import { render, staticRenderFns } from "./User_Order.vue?vue&type=template&id=e061dfac&scoped=true&"
import script from "./User_Order.vue?vue&type=script&lang=js&"
export * from "./User_Order.vue?vue&type=script&lang=js&"
import style0 from "./User_Order.vue?vue&type=style&index=0&id=e061dfac&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e061dfac",
  null
  
)

export default component.exports