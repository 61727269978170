<template>
  <div class="order">
    <!-- 导航栏 -->
    <van-nav-bar
      class="nav-bar"
      :title="this.lottery.title"
      :class="!$isLinkGame ? 'black-bc yellow-color' : 'red-bc white-color'"
    >
      <i
        slot="left"
        class="iconfont icon-jiantou1"
        @click="$router.back()"
        :color="!$isLinkGame ? '#ffd400' : '#fff'"
      ></i>
      <template #title>
        <span :class="!$isLinkGame ? 'yellow-color' : 'white-color'"
          >this.lottery.title</span
        >
      </template>
      <template #right>
        <van-popover
          v-model="showPopover"
          theme="dark"
          trigger="click"
          :actions="actions"
          placement="bottom-end"
          @select="HanddleClickitem"
        >
          <template #reference>
            <button>Chi tiết</button>
          </template>
        </van-popover>
      </template>
    </van-nav-bar>
    <div class="order-title">
      <van-cell-group class="title-info">
        <van-cell class="balance">
          <template #title>
            <div class="title">Số dư khả dụng</div>
          </template>
          <template #label>
            <div class="balance-left-money">
              ₫{{ parseFloat(userMoney).toFixed(2) }}
            </div>
          </template>
          <template #default>
            <van-button
              type="primary"
              size="mini"
              @click="$router.push({ path: '/recharge' })"
              >Nạp tiền</van-button
            >
          </template>
        </van-cell>
        <van-cell class="period">
          <template #title>
            <div class="title">Kỳ mở</div>
          </template>
          <template #label>
            <div class="period-left-number">
              <span v-if="lotteryEnd > 0">
                {{
                  `Số đuôi kỳ mở ${lotteryIng.expect
                    .toString()
                    .substr(lotteryIng.expect.toString().length - 4)}`
                }}
              </span>
              <span v-else>khai trương tiếp theo</span>
            </div>
          </template>
          <template #default>
            <div class="title">Thời gian</div>
            <div class="period-right-time">
              <van-count-down
                v-if="lotteryEnd > 0"
                :time="lotteryEnd * 1000"
                format="mm : ss"
              />
              <span v-else>Về để bắt đầu</span>
            </div>
          </template>
        </van-cell>
      </van-cell-group>

      <!-- 下注列表 -->
      <div class="lottery-box">
        <div class="lottery-list">
          <span class="title">Phát hành</span>
          <span class="title">Số mở thưởng</span>
          <span class="title">Số đuôi</span>
          <span class="title">kết quả</span>
        </div>
        <div class="lottery-list-item">
          <van-collapse v-model="activeNames">
            <van-collapse-item name="1">
              <template #title>
                <div class="item">
                  <span class="medium">{{ drawObj.expect }}</span>
                  <span class="medium">{{
                    drawObj.code ? drawObj.code : '/'
                  }}</span>
                  <span
                    class="number"
                    :style="
                      drawObj.myNum &&
                      (drawObj.myNum == '0' ||
                        drawObj.myNum == '2' ||
                        drawObj.myNum == '4' ||
                        drawObj.myNum == '6' ||
                        drawObj.myNum == '8')
                        ? { color: isOdlGame ? '#ff0000' : '#ff8a00' }
                        : { color: '#13cc00' }
                    "
                    >{{ drawObj.myNum ? drawObj.myNum : '/' }}</span
                  >
                  <span>
                    <i v-if="!drawObj.code"></i>
                    <i
                      v-else
                      class="result-color"
                      :style="
                        drawObj.myNum == '0' ||
                        drawObj.myNum == '2' ||
                        drawObj.myNum == '4' ||
                        drawObj.myNum == '6' ||
                        drawObj.myNum == '8'
                          ? {
                              'background-color': isOdlGame
                                ? '#ff0000'
                                : 'ff8a00',
                            }
                          : { 'background-color': '#13cc00' }
                      "
                    ></i>
                    <i
                      v-if="
                        drawObj.code &&
                        (drawObj.myNum == '0' || drawObj.myNum == '5')
                      "
                      class="result-color"
                      style="background-color: #b400ff"
                    ></i
                  ></span>
                </div>
              </template>
              <template #default>
                <div class="list">
                  <van-list loading-text="loading" finished-text="No more...">
                    <van-cell v-for="item in historyLists" :key="item.id">
                      <template #default>
                        <div class="item">
                          <span class="medium">{{ item.expect }}</span>
                          <span class="medium">{{
                            item.code ? item.code : '/'
                          }}</span>
                          <span
                            class="number"
                            :style="
                              item.code &&
                              (lastNumber(item.code) == 0 ||
                                lastNumber(item.code) == 2 ||
                                lastNumber(item.code) == 4 ||
                                lastNumber(item.code) == 6 ||
                                lastNumber(item.code) == 8)
                                ? { color: isOdlGame ? '#ff0000' : '#ff8a00' }
                                : { color: '#13cc00' }
                            "
                            >{{ item.code ? lastNumber(item.code) : '/' }}</span
                          >
                          <span>
                            <i v-if="!item.code"></i>
                            <i
                              v-else
                              class="result-color"
                              :style="
                                item.myNum == '0' ||
                                item.myNum == '2' ||
                                item.myNum == '4' ||
                                item.myNum == '6' ||
                                item.myNum == '8'
                                  ? {
                                      'background-color': isOdlGame
                                        ? '#ff0000'
                                        : '#ff8a00',
                                    }
                                  : { 'background-color': '#13cc00' }
                              "
                            ></i>
                            <i
                              v-if="
                                item.code &&
                                (item.myNum == '0' || item.myNum == '5')
                              "
                              class="result-color"
                              style="background-color: #b400ff"
                            ></i>
                          </span>
                        </div>
                      </template>
                    </van-cell>
                  </van-list>
                </div>
              </template>
            </van-collapse-item>
          </van-collapse>
        </div>
      </div>
    </div>

    <!-- 聊天室区域 -->
    <ChatRoom
      ref="chatRoom"
      class="chatRoom"
      :lotteryType="lottery"
      :userid="userInfo.id"
      :isOdlGame="isOdlGame"
    ></ChatRoom>

    <!-- 新的确认投注窗口 -->
    <div v-if="lotteryConfirmShowNew" class="lottery-confirm-new">
      <div class="lottery-confirm-main-new">
        <div class="confirm-head-new">
          <span
            class="confirm-bar-new"
            :style="{ backgroundColor: `${settingConfirmInfo.styleColor}` }"
            >{{ settingConfirmInfo.lotteryConfirmTitle }}</span
          >
        </div>
        <div class="lottery-confirm-mian-cont">
          <div class="lottery-confirm-mian-cont-select">
            <span class="lottery-confirm-mian-cont-select-text"
              >số tiền tùy chọn(₫)</span
            >
            <div class="lottery-confirm-mian-cont-select-kk">
              <span
                v-for="(item, index) in lotteryConfirmKK"
                :key="index"
                class="lottery-confirm-mian-cont-select-key"
                :class="{
                  'lottery-confirm-mian-cont-select-key-on':
                    moneyShow === item.key,
                }"
                @click="handleSelectConfirmMoney(item)"
                >{{ item.key }}</span
              >
            </div>
          </div>
          <div class="lottery-confirm-mian-cont-input-box">
            <span class="lottery-confirm-mian-cont-select-text"
              >Vui lòng nhập số tiền(₫)</span
            >
            <div class="lottery-confirm-mian-cont-input">
              <span class="cont-input-text">₫&nbsp;</span>
              <!-- 请输入金额 -->
              <input
                class="cont-input"
                type="number"
                placeholder="Vui lòng nhập số tiền"
                v-model="moneyShow"
                :autofocus="autofocus2"
                @keyboard="keyboardStatus"
              />
            </div>
          </div>
          <div class="lottery-confirm-mian-money-show-box">
            <div class="lottery-confirm-mian-cont-select-text">
              Tổng số tiền hợp đồng là
            </div>
            <div class="lottery-confirm-mian-cont-show-money">
              ₫&nbsp;{{ moneyShowTofixed2(moneyShow) }}
            </div>
          </div>
          <div class="lottery-confirm-mian-money-checkbox">
            <div class="lottery-confirm-mian-money-checkbox-fake-box">
              <van-checkbox
                v-model="checked"
                shape="square"
                class="check-text1"
                checked-color="#666"
                >tôi đồng ý
                <span class="check-text2">luật chơi</span></van-checkbox
              >
            </div>
          </div>
          <div class="lottery-confirm-mian-btn-box">
            <button
              class="lottery-confirm-mian-btn-box-colse"
              text="Hủy bỏ"
              backgroundColor="#ededed"
              color="#999999"
              fontSize="36"
              @click="handleCloseConfirmShowBox"
            >
              Hủy bỏ
            </button>
            <button
              class="lottery-confirm-mian-btn-box-confirm"
              :style="{ backgroundColor: `${settingConfirmInfo.styleColor}` }"
              text="xác nhận"
              fontSize="36"
              @click="handleConfirm"
            >
              xác nhận
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="footer">
      <!-- <div class="footer-chat" @click="chatShow = true">
        <img src="@/assets/images/new-img/lt.png" alt="" />
        Write a message...
      </div> -->
      <div class="footer-play" @click="showRoomSeed = true">Đặt cược ngay</div>
    </div>

    <div class="chat" v-show="chatShow">
      <van-button type="danger" size="small" @click="chatShow = false"
        >return</van-button
      >
      <van-button
        type="info"
        size="small"
        :disabled="text === ''"
        :class="{ gray: text === '' }"
        @click="sendMessage"
        >send</van-button
      >
      <input type="text" v-model="text" />
    </div>

    <template v-if="isOdlGame">
      <RoomSend
        v-show="showRoomSeed"
        @clickClose="showRoomSeed = false"
        @clickSend="lotterySetting"
      ></RoomSend>
    </template>
    <template v-else>
      <RoomSend2
        v-show="showRoomSeed"
        @clickClose="showRoomSeed = false"
        @clickSend="lotterySetting"
      ></RoomSend2>
    </template>

    <van-loading v-if="isshowLoading" type="spinner" />
    <van-popup
      v-model="isShowPopup"
      position="bottom"
      :style="{ height: '30%' }"
    >
    </van-popup>
  </div>
</template>

<script>
import * as RongIMLib from '@rongcloud/imlib-v4'
import RoomSend from './room_send.vue'
import RoomSend2 from './room_send2.vue'
import ChatRoom from './Chat_room.vue'
import {
  getLotteryList,
  getLotteryInfo,
  getLotteryPost,
} from '@/api/lottery.js'
import { runNum, formatMobile, jsonStringify } from '@/utils/method.js'
import { userinfo } from '@/api/use.js'
import { saveMessage, historyMessage } from '@/api/message.js'
import request from '@/utils/request'
import { NotLoginLinkPage } from '@/utils/storage'

export default {
  name: '',
  components: {
    RoomSend,
    ChatRoom,
    RoomSend2,
  },
  data() {
    return {
      isShowPopup: false,
      showPopover: false,
      actions: [
        { text: 'Kiểu chơi' },
        // { text: 'tỷ lệ cược' },
        { text: 'Dịch vụ khách hàng' }, // 没有客服
        { text: 'Lịch sử đặt cược' },
      ],
      activeNames: ['0'],
      list: [1, 2, 3],
      IM: null,
      loading: false,
      finished: false,
      showRoomSeed: false,
      checked: true,
      isshowLoading: false, // 控制是否显示加载
      lottery: {}, // 跳转过来接受参数
      drawObj: {}, // 第一条开奖数据
      historyLists: [], // 开奖列表数据
      lotteryIng: [], // 正在开奖的数据
      lotteryEnd: 0, // 离开奖的时间
      userMoney: 0.0, // 用户余额
      totalMoney: '0.00',
      moneyShow: '10', // 显示的金额
      mtype: this.settingType, // 'yuan'
      lotteryConfirmShowNew: false,
      hall: false,
      hall_field: 0,
      hall_show: false,
      userInfo: {},
      settingOdds: '',
      settingNumber: 0,
      settingNumberInfo: '',
      settingOneOrderMoney: 0,
      settingOneMethodMoney: {},
      settingSelectCodeString: {},
      serviceURL: '',
      settingConfirmInfo: {},
      newMessage: {},
      messageLists: [], // 保存历史聊天记录
      lotteryConfirmKK: [
        {
          key: '10',
          isSelect: true,
        },
        {
          key: '100',
          isSelect: false,
        },
        {
          key: '1000',
          isSelect: false,
        },
        {
          key: '10000',
          isSelect: false,
        },
      ],
      autofocus2: false,
      isCheck: true,
      chatShow: false,
      text: '',
      isOdlGame: true,
      infoShowObj: {
        绿: '绿',
        紫: '紫',
        红: '红',
      },
    }
  },
  mounted() {
    if (this.lottery.type.indexOf('qq') === -1) {
      this.isOdlGame = true
      this.infoShowObj = {
        绿: '绿',
        紫: '紫',
        红: '红',
      }
    } else {
      this.isOdlGame = false
      this.infoShowObj = {
        绿: '足球',
        紫: '橄榄球',
        红: '篮球',
      }
    }
    this.hall = runNum(this.lottery.hall) === 1
    this.hall_field = runNum(this.lottery.hall_field)
    if (this.hall) {
      this.hall_show = true
    }
    this.lotteryEndWatch()
    this.getLotteryList()
    this.getHistoryMessage()
    this.getLotteryInfo()
    this.lotteryListWatch()
    this.getAllInfo()
  },
  created() {
    this.initRong()
    this.getUserInfo()
    this.lottery = this.$store.state.lottery
    this.getService()
  },

  watch: {
    lotteryEnd: function (val) {
      if (val === 49) {
        this.$toast(
          `Số giai đoạn đã thay đổi, hiện tại là ${this.lotteryIng.expect}`
        )
      } else if (val < 0 && this.lotteryIng.expect !== '') {
        const also = 10 - Math.abs(val % 10)
        if (also === 2) {
          this.getLotteryInfo()
          this.getLotteryList()
        }
      }
    },
  },
  methods: {
    // 初始化融云
    initRong() {
      this.IM = RongIMLib.init({
        appkey: 'e5t4ouvpe6y7a', // 融云appkey
      })
    },
    HanddleClickitem(action) {
      if (action.text === 'Kiểu chơi') {
        this.$router.push({
          path: '/gameShows',
          query: { title: 'Kiểu chơi', lotteryType: 'method' },
        })
      } else if (action.text === 'tỷ lệ cược') {
        this.$router.push({
          path: '/gameShows',
          query: { title: 'tỷ lệ cược', lotteryType: 'odds' },
        })
      } else if (action.text === 'Dịch vụ khách hàng') {
        this.isShowPopup = true
        window.location.href = this.serviceURL
      } else if (action.text === 'Lịch sử đặt cược') {
        if (this.$route.query.key) {
          if (!this.appLinkClick) {
            this.$toast(
              'đăng nhập không đúng Không thể sử dụng chức năng này. Vui lòng liên hệ với dịch vụ Bạn vẫn chưa đăng ký. Hãy bắt đầu.'
            )
            return false
          }
        }
        if (!NotLoginLinkPage()) {
          this.$router.push('/login')
          return false
        }
        this.$router.push({
          path: '/single',
          query: { title: this.lottery.title, type: this.lottery.type },
        })
      }
    },

    async getUserInfo() {
      const {
        data: { ret, data },
      } = await userinfo()
      if (ret === 1) {
        this.userInfo = data
      }
    },
    //   离开奖结束倒计时lotteryEnd的处理
    lotteryEndWatch() {
      var that = this
      const timer1 = window.setInterval(() => {
        setTimeout(() => {
          that.lotteryEnd--
        }, 0)
      }, 1000)
      this.$once('hook:beforeDestroy', () => {
        clearInterval(timer1)
      })
    },

    //   获取开奖列表
    lotteryListWatch() {
      var that = this
      const timer2 = window.setInterval(() => {
        setTimeout(() => {
          that.getLotteryList()
        }, 0)
      }, 2000)
      this.$once('hook:beforeDestroy', () => {
        clearInterval(timer2)
      })
    },

    //   获取开奖数据列表
    async getLotteryList() {
      const {
        data: { ret, data },
      } = await getLotteryList({ type: this.lottery.type, page: 1 })
      if (ret === 1) {
        this.historyLists = data.lists
        // console.info('historyLists', this.historyLists)
        // this.drawObj = this.historyLists[0]
        this.drawObj = this.historyLists[1]
        // myNum 最后一位数
        this.drawObj.myNum = this.drawObj.code
          ? this.drawObj.code.substr(this.drawObj.code.length - 1, 1)
          : ''
        this.historyLists.shift()
        this.historyLists.shift()
        this.historyLists.forEach((_) => {
          _.myNum = _.code ? _.code.substr(_.code.length - 1, 1) : ''
        })
      }
    },
    async getService() {
      const {
        data: { data, ret },
      } = await request({
        method: 'get',
        url: 'other/config',
        params: {
          act: 'system',
          refresh: this.isLoading === true ? 1 : 0,
        },
      })
      if (ret === 1) {
        this.serviceURL = data.service_url
      }
    },
    // 获取正在开奖数据
    async getLotteryInfo() {
      const {
        data: { data, ret },
      } = await getLotteryInfo({
        type: this.lottery.type,
      })
      if (ret === 1) {
        if (data.ing.status === '待开奖') {
          this.userMoney = data.money
          this.lotteryIng = data.ing
          this.lotteryEnd =
            data.ing.endtime - Math.round(new Date().getTime() / 1000)
        }
        // console.log(data)
      }
    },

    // 获取开奖code的最后一位
    lastNumber(code) {
      var str = code.toString()
      var lastNumber = parseInt(str.substr(str.length - 1, 1))
      return lastNumber
    },
    /**
     * 新下注
     */
    handleConfirm() {
      const num = parseInt(this.userMoney) - runNum(this.moneyShow)
      if (num < 0.0) {
        this.$toast({
          message: 'Thiếu cân bằng!',
          position: 'bottom',
        })
        this.showRoomSeed = false
        this.lotteryConfirmShowNew = false
        return
      }
      this.isshowLoading = true
      if (runNum(this.moneyShow) <= 0) {
        // eeui.toast('请输入有效的单注金额')
        this.$toast('Vui lòng nhập một số tiền đặt cược hợp lệ')
        return
      } else if (runNum(this.moneyShow) < 10) {
        // eeui.toast('下注金额不能小于10')
        this.$toast('Số tiền đặt cược không được Xỉu hơn ₫ 10 !')
        return false
      }
      if (runNum(this.lotteryIng.expect) === 0) {
        // eeui.toast({ gravity: 'middle', message: '正在开奖，请稍后...' })
        this.$toast({
          //   gravity: 'middle',
          message: 'Đang bốc thăm, vui lòng đợi ...',
        })
      }
      this.lotteryPostNew()
    },
    async lotteryPostNew() {
      this.$toast.loading({ forbidClick: true, loadingType: 'spinner' })
      const {
        data: { ret, data },
      } = await getLotteryPost({
        type: this.lottery.type,
        expect: this.lotteryIng.expect,
        method: this.settingConfirmInfo.method, // hl-dwd/hl-sb
        code: this.settingConfirmInfo.code,
        money: this.moneyShow,
        mtype: 'yuan', // 'yuan'
      })
      console.log(data)
      this.showRoomSeed = false
      this.lotteryConfirmShowNew = false
      if (ret === 1) {
        this.showRoomSeed = false
        this.lotteryConfirmShowNew = false
        this.settingType = 'yuan'
        this.userMoney = data.after_money
        this.totalMoney = data.after_totalMoney
        const message = {
          type: 'lottery',
          userid: this.userInfo.id,
          username: this.userInfo.username,
          userphone: formatMobile(this.userInfo.userphone),
          userimg: this.userInfo.userimg,
          lottery: {
            expect: data.expect,
            method: data.method,
            number: data.num,
            code: data.code,
            totalMoney: runNum(data.totalMoney, 2),
          },
        }
        const IM = this.IM
        // 注: im 实例通过 RongIMLib.init 获取(单个页面仅需初始化一次)
        var chatRoom = IM.ChatRoom.get({
          id: this.lottery.roomid,
        })
        chatRoom
          .send({
            messageType: RongIMLib.MESSAGE_TYPE.TEXT,
            content: message,
            isPersited: true, // 是否存储在服务端,默认为 true
            isCounted: true, // 是否计数. 计数消息接收端接收后未读数加 1，默认为 true
            pushContent: 'user 发送了一条消息', // Push 显示内容
            pushData: 'Push 通知时附加信息', // Push 通知时附加信息, 可不填
            isStatusMessage: false, // 设置为 true 后 isPersited 和 isCounted 属性失效
          })
          .then(function (message) {
            console.log('发送 s:person 消息成功', message)
          })

        // 保存下注记录
        const res = await saveMessage({
          type: this.lottery.type,
          message: jsonStringify(message),
        })
        if (res.data.ret === 1) {
          this.$refs.chatRoom.Message = message
          this.$toast.clear()
        }
      } else if (ret === 0) {
        this.$toast('Đang chờ khai trương！')
      }
    },

    lotterySetting(info) {
      console.info('info', info)
      this.getMethodInfo = info.selectType
      this.settingOdds = info.odds
      this.settingNumber = info.number
      this.settingNumberInfo = info.numberInfo
      this.settingOneOrderMoney = info.oneOrderMoney
      this.settingOneMethodMoney = info.oneMethodMoney
      this.settingSelectCodeString = info.selectCodeString
      // this.lotteryConfirmShow = true

      /**
       * 以下为二次开发添加内容
       */
      this.lotteryConfirmKK.forEach((_) => {
        _.isSelect = false
      })
      this.lotteryConfirmKK[0].isSelect = true
      const colorList = [
        {
          key: 'green',
          value: this.isOdlGame ? 'chọn màu xanh lá cây' : 'Join Football',
          color: '#13cc00',
          codeKey: '绿',
        },
        {
          key: 'violet',
          value: this.isOdlGame ? 'chọn màu tím' : 'Join Rugby',
          color: '#b400ff',
          codeKey: '紫',
        },
        {
          key: 'red',
          value: this.isOdlGame ? 'chọn màu đỏ' : 'Join Basketball',
          color: this.isOdlGame ? '#ff0000' : '#ff8a00',
          codeKey: '红',
        },
      ]
      const ind = colorList.findIndex((_) => {
        return _.key === info.key
      })
      this.settingConfirmInfo = info
      this.settingConfirmInfo.lotteryConfirmTitle =
        info.selectType === 'qiuqiu' ? 'chọn số' : colorList[ind].value
      this.settingConfirmInfo.styleColor =
        info.selectType === 'qiuqiu' ? '#009cff' : colorList[ind].color
      this.settingConfirmInfo.code =
        info.selectType === 'qiuqiu' ? info.key : colorList[ind].codeKey
      this.settingConfirmInfo.method =
        info.selectType === 'qiuqiu' ? 'hl-dwd' : 'hl-sb'

      this.lotteryConfirmKK.forEach((_) => {
        if (_.isSelect) {
          this.moneyShow = _.key * 1
        }
      })
      this.lotteryConfirmShowNew = true
      console.log(this.settingConfirmInfo)
    },
    /**
     * money显示保留两位小数
     */
    moneyShowTofixed2(val) {
      return (val * 1).toFixed(2)
    },
    handleCheck() {
      this.isCheck = !this.isCheck
    },
    handleCloseConfirmShowBox() {
      this.lotteryConfirmShowNew = false
      this.autofocus2 = false
      //   eeui.keyboardHide()
    },
    /// 监听键盘收回
    keyboardStatus(obj) {
      // console.info(obj)
      if (obj.isShow === false) {
        this.autofocus = false
        this.autofocus2 = false
      }
    },

    // 点击切换金额
    handleSelectConfirmMoney(item) {
      this.moneyShow = item.key
    },

    formatMobile(phone) {
      return phone.substring(0, 3) + '****' + phone.substring(phone.length - 4)
    },

    // 获取历史聊天记录
    async getHistoryMessage() {
      this.$toast('Loading...')
      const {
        data: { ret, data },
      } = await historyMessage({ type: this.lottery.type })
      if (ret === 1) {
        for (var obj of data) {
          if (obj !== 'Array') {
            this.messageLists.push(JSON.parse(obj))
          }
        }
        this.$refs.chatRoom.messageLists = this.messageLists
        this.$toast.clear()
      }
    },

    async sendMessage() {
      this.chatShow = false
      var speakText = this.text.trim()
      if (this.$store.state.user === '') {
        this.$toast('Danh tính của bạn không hợp lệ, vui lòng đăng nhập lại!')
        return
      }
      var message = {
        type: 'text',
        userid: this.userInfo.id,
        username: this.userInfo.username,
        userphone: this.formatMobile(this.userInfo.userphone),
        userimg: this.userInfo.userimg,
        text: speakText,
      }
      const IM = this.IM
      // 注: im 实例通过 RongIMLib.init 获取(单个页面仅需初始化一次)
      var chatRoom = IM.ChatRoom.get({
        id: this.lottery.roomid,
      })
      chatRoom
        .send({
          messageType: RongIMLib.MESSAGE_TYPE.TEXT, // 'RC:TxtMsg'
          content: message,
        })
        .then(function (message) {
          console.log('发送文字消息成功', message)
        })
      const {
        data: { ret },
      } = await saveMessage({
        type: this.lottery.type,
        message: message,
      })
      if (ret === 1) {
        this.$refs.chatRoom.getHistoryMessage()
        this.text = ''
      }
    },

    getAllInfo() {
      const timer1 = window.setInterval(() => {
        setTimeout(() => {
          this.newGetAllInfo()
        }, 0)
      }, 5000)
      this.$once('hook:beforeDestroy', () => {
        clearInterval(timer1)
      })
    },

    async newGetAllInfo() {
      const {
        data: { data, ret, msg },
      } = await request({
        method: 'get',
        url: 'lottery/crontab',
        params: {
          type: this.$route.query.type || this.lottery.type,
        },
      })
      if (ret === 1) {
        const userInfo = data.user
        let list = data.message.map((_) => {
          return JSON.parse(_)
        })
        this.userMoney = userInfo ? userInfo.money : 0
        if (list.length) {
          let oldList = this.messageLists
            .slice(-10, this.messageLists.length)
            .map((item) => {
              return item
            })
          let len = oldList.length ? oldList.length - 1 : 0
          if (!len) {
            return false
          }
          list.forEach((_) => {
            if (
              _.type !== oldList[len].type &&
              _.lottery.method !== oldList[len].item.lottery.method &&
              _.lottery.code !== oldList[len].lottery.code &&
              _.lottery.expect !== oldList[len].lottery.expect &&
              _.lottery.totalMoney !== oldList[len].lottery.totalMoney &&
              _.lottery.number !== oldList[len].lottery.number
            ) {
              this.messageLists.push(_)
              this.$refs.chatRoom.messageLists.push(_)
            }
          })
        }
      } else {
        Dialog.alert({
          title: 'Lưu ý',
          message: msg,
        }).then(() => {})
      }
    }, // 获取后面的新接口返回的三类
  },
}
</script>

<style lang="less" scoped>
.order {
  .nav-bar {
    width: 100%;
    position: fixed;
    // z-index: 9999;
  }
  .order-title {
    width: 100%;
    position: fixed;
    z-index: 2;
    top: 1.22667rem;
  }
  .chatRoom {
    height: calc(100% - 465px);
  }
  height: 100%;
  font-family: 'PingFang-Regular';
  position: relative;
  box-sizing: border-box;
  .van-nav-bar {
    /deep/ .van-nav-bar__content {
      // background-color: #ff0000;
    }
    /deep/ .van-nav-bar__title {
      // color: #fff;
    }
    button {
      background-color: #eeeeee;
      border: none;
      border-radius: 5px;
      color: rgb(51, 20, 20);
      font-size: 34px;
    }
    .iconfont {
      font-size: 44px;
      // color: #fff;
    }
    /deep/ .van-popover {
      .van-popover__content {
        width: 405px;
      }
      /deep/ .van-popover__action {
        /deep/ .van-popover__action-text {
          width: 100px;
        }
      }
    }
  }

  .van-cell-group {
    margin: 15px 10px 0;
    .van-cell {
      padding: 0;
    }
    .balance {
      .balance-left-money {
        font-size: 36px;
        color: #ff0000;
        text-align: center;
      }
      /deep/ .van-cell__value {
        text-align: center;
      }
    }

    .period {
      padding-top: 5px;
      .period-left-number {
        font-size: 28px;
        color: #000;
        text-align: center;
      }
      .period-right-time {
        text-align: center;
        font-size: 30px;
        color: #000;
      }
    }
  }

  .title {
    text-align: center;
    font-size: 24px;
    color: #858585;
  }
  .lottery-box {
    padding-top: 5px;
    margin: 0 10px;
    background-color: #fff;
    span {
      text-align: center;
      &:first-child {
        width: 34%;
      }
      &:nth-child(2) {
        width: 22%;
      }
      &:nth-child(3) {
        width: 22%;
      }
      &:nth-child(4) {
        width: 22%;
        display: flex;
        justify-content: center;
        align-items: center;
        i {
          // margin-top: 15px;
        }
      }
    }
    .lottery-list {
      padding-right: 30px;
      display: flex;
      justify-content: space-around;
      align-items: center;
    }

    .lottery-list-item {
      .item {
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid #eeeeee;
        font-family: 'PingFang-Medium';
        span {
          padding-left: 30px;
        }

        .van-cell__right-icon {
          padding-right: 20px;
        }

        .medium {
          font-size: 24px;
        }
        .number {
          font-size: 30px;
        }
      }
      .result-color {
        width: 20px;
        height: 20px;
        display: block;
        border-radius: 50px;
        margin-right: 4px;
      }
    }

    .van-collapse {
      /deep/ .van-cell {
        padding: 0 !important;
      }
      /deep/ .van-collapse-item__content {
        padding: 0;
      }
    }
    .lottery-list-item {
      .list {
        padding-right: 40px;
        height: 400px;
        overflow: auto;
      }
    }
  }

  .footer {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: space-around;
    background-color: #fff;
    .footer-chat {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50%;
      height: 84px;
      box-sizing: border-box;
      padding: 10px;
      border-radius: 37px;
      background-color: #ededed;
      text-align: center;
      font-size: 29px;
      color: #b2b2b2;
      img {
        height: 54px;
        margin-right: 5px;
      }
    }
    .footer-play {
      height: 84px;
      width: 100%;
      background-image: url('../../assets/images/new-img/playnow.png');
      background-size: cover;
      font-size: 40px;
      color: #fff;
      line-height: 74px;
      text-align: center;
    }
  }

  .lottery-confirm-new {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 999;
  }
  .lottery-confirm-main-new {
    width: 584px;
    background-color: #ffffff;
    border-radius: 10px;
  }
  .confirm-head-new {
    height: 98px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .confirm-bar-new {
    width: 584px;
    height: 98px;
    line-height: 98px;
    color: #fff;
    background-color: #13cc00;
    text-align: center;
    align-items: center;
    font-size: 32px;
  }
  .lottery-confirm-mian-cont {
    width: 584px;
    background-color: #fff;
  }
  .lottery-confirm-mian-cont-select {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .lottery-confirm-mian-cont-select-text {
    font-size: 30px;
    color: #838383;
    height: 68px;
    line-height: 68px;
  }
  .lottery-confirm-mian-cont-select-kk {
    width: 516px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 0 34px;
  }
  .lottery-confirm-mian-cont-select-key {
    width: 120px;
    height: 70px;
    font-size: 34px;
    color: #ff0000;
    border-radius: 6px;
    border-style: solid;
    border-width: 1px;
    border-color: #838383;
    text-align: center;
    line-height: 70px;
  }
  .lottery-confirm-mian-cont-select-key-on {
    color: #fff;
    background-color: #ffcf0e;
    border-color: #ffcf0e;
  }
  .lottery-confirm-mian-cont-input-box,
  .lottery-confirm-mian-money-show-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .lottery-confirm-mian-cont-input {
    height: 70px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .cont-input-text {
    font-size: 60px;
    color: #323232;
    margin-right: 15px;
    margin: 28px 0;
  }
  .cont-input {
    width: 272px;
    height: 70px;
    font-size: 40px;
    border: 0;
    border-bottom: 1px solid #000;
    color: #323232;
    text-align: center;
  }
  .lottery-confirm-mian-cont-show-money {
    font-size: 48px;
    color: #fe0000;
    margin: 5px 0 35px;
  }
  .lottery-confirm-mian-money-checkbox {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .lottery-confirm-mian-money-checkbox-fake-box {
    width: 516px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 34px;
  }
  .fake-check {
    width: 25px;
    height: 25px;
    border-style: solid;
    border-width: 1px;
    border-color: #838383;
    border-radius: 4px;
  }
  .check-icon {
    width: 25px;
    height: 25px;
  }
  .check-text1 {
    font-size: 24px;
    color: #838383;
  }
  .check-text2 {
    font-size: 24px;
    color: #0066ff;
  }
  .lottery-confirm-mian-btn-box {
    width: 584px;
    flex-direction: row;
    margin-top: 16px;
  }
  .lottery-confirm-mian-btn-box-colse {
    width: 292px;
    height: 88px;
    font-size: 28px;
    color: #666;
    border: 0;
  }
  .lottery-confirm-mian-btn-box-confirm {
    width: 292px;
    height: 88px;
    border: 0;
    font-size: 28px;
    color: #fff;
  }
  .chat {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 15px;
    width: 100%;
    box-sizing: border-box;
    background-color: #fff;
    display: flex;
    align-items: center;
    .van-button {
      width: 15%;
      margin-right: 15px;
    }
    .gray {
      background-color: gray;
    }
    input {
      padding: 0;
      margin: 0;
      border: 0;
      width: 70%;
      border-bottom: 1px solid blue;
      font-size: 32px;
    }
  }
}
</style>
